import { request } from "../shared/utils/axios";
import storage from "../shared/utils/storage";

/**
 * 登录
 */
export async function login({ account, password }) {
    return await request({
        type: "post",
        url: "/login",
        data: { account, password },
    });
}

/**
 *
 * @param {string} token
 * 更新Token
 * @returns
 */
export async function refreshToken() {
    return await request(
        {
            type: "post",
            url: "/update/token",
            headers: { Authorization: "Bearer " + storage("refresh-token") },
        },
        true,
    );
}

export async function getAllMenu() {
    return await request({
        type: "get",
        url: "/perm/menu",
    });
}
