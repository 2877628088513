import apiFactory from "../../../apis/apiFactory";

const name = "document";
const apiInstance = apiFactory({
    name,
    onCallApi(method, params) {
        const { url } = params;
        switch (method) {
            case "query":
                params.url = url.replace(name, name + "/getResById");
                params.type = "post";
                break;
            case "create":
                params.url = name;
                break;
            case "remove":
                params.type = "get";
                params.url = url.replace(name, name + "/delete");
                break;
            case "update":
                params.type = "post";
                params.url = name + "/update";
                break;
            default:
                break;
        }
    },
});
export default apiInstance;
