import { registerTable } from "../../../shared/libs/table-engine";
import { menusApi, permissionsApi, menusPermApi } from "./api";
// 权限表字段
export const permissionsTable = registerTable("permissions", {
    api: permissionsApi,
    structure: [
        {
            label: "Path",
            key: "path",
            isPrimary: true,
            isLabel: true,
            canEdit: false,
        },
        {
            label: "方法",
            key: "method",
            isPrimary: true,
            isLabel: true,
            canEdit: false,
        },
        {
            label: "描述",
            key: "desc",
            canEdit: false,
        },
    ],
});

/**
 * 菜单类型
 */
const MENU_TYPES = [
    {
        label: "菜单",
        value: 1,
    },
    {
        label: "TAB",
        value: 2,
    },
    {
        label: "按钮",
        value: 3,
    },
];

/**
 * 菜单与权限对应表
 */

export const menusPermTable = registerTable("menus-perm", {
    api: menusPermApi,
    structure: [
        {
            key: "id",
            label: "ID",
            isPrimary: true,
            canEdit: false,
        },
        {
            key: "menuId",
            label: "菜单ID",
            type: "table:menus.id",
        },
        {
            key: "apiUrl",
            label: "apiUrl",
            type: "table:permissions.path",
        },
        {
            key: "apiMethod",
            label: "apiMethod",
            type: "table:permissions.method",
        },
    ],
});

/** 菜单 */
/**
 * registerTable 变成注册table，这样后续其他表用时可以直接使用
 */
export const menusTable = registerTable("menus", {
    name: "menus",
    api: menusApi,
    structure: [
        {
            label: "ID",
            key: "id",
            isPrimary: true,
            canEdit: false,
        },
        {
            label: "名称",
            key: "name",
            isLabel: true,
        },
        {
            label: "父级",
            key: "parentId",
            type: "table:menus.id",
            default: { value: "0", label: "根节点" },
            showInList: false,
        },
        {
            label: "Code",
            key: "code",
            type: "string",
        },
        {
            label: "类型",
            key: "type",
            type: MENU_TYPES,
        },
        {
            label: "排序",
            key: "orderNum",
            type: "number",
        },
        {
            label: "权限列表",
            key: "menuPermList",
            type: ["table:menus-perm"],
            showInList: false,
        },
    ],
});

/**
 * 获取表的主键盘
 */
