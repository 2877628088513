import { useRef, useEffect } from "react";
import { Row, Col, Button, message } from "antd";
import { useLocation } from "react-router-dom";
import createDocumentParams from "../helps/createDocumentParams";
import * as documentApi from "../../../apis/document";
import * as modal from "../components/FormModal";

const Footer = ({ onOk = () => {}, children = "保存" }) => (
    <Row justify="end">
        <Col>
            <Button onClick={onOk} type="primary">
                {children}
            </Button>
        </Col>
    </Row>
);

export default function DocumentEdit() {
    const docRef = useRef();
    const location = useLocation();

    const updateDocById = async (value) => {
        const res = await documentApi.updateDocumentById(
            createDocumentParams(value),
        );
        if (res.success) {
            message.success("更新成功");
        } else {
            message.error("更新失败！" + res.msg);
        }
        return res;
    };

    useEffect(() => {
        const id = location.pathname.split("/").pop();
        id &&
            documentApi.getResById({ id }).then((res) => {
                modal.edit({
                    noModal: true,
                    data: res.data || {},
                    getContainer: () => docRef.current,
                    submit: updateDocById,
                    renderFooter: ({ onOk }) => <Footer onOk={onOk} />,
                });
            });
    }, [location.pathname]);

    return <div ref={docRef} />;
}
