import { Input } from "antd";
import ReactQuill from "react-quill";
import { SketchPicker } from "react-color";
import UploadComponent from "../../../../shared/components/Upload";
const { TextArea } = Input;

function createComponentFactory(type) {
    switch (type) {
        case "richText":
            return ReactQuill;
        case "color":
            return SketchPicker;
        case "image":
            return UploadComponent;
        case "html":
            return TextArea;
        case "text":
        default:
            return Input;
    }
}

export default function DocInput({ type, ...otherProps }) {
    const Component = createComponentFactory(type?.value);
    const props = type?.value === "image" ? { isString: true } : {};

    return <Component {...otherProps} {...props} />;
}
