import { isValidElement } from "react";
import { Input, InputNumber } from "antd";
import UploadComponent from "../Upload";
import TableSelect from "./TableSelect";
import CommonSelect from "./CommonSelect";
import { isTableField } from "../../../modules/table";

export function inputFactory(type, tableName, props) {
    // 如果字段关联其他表
    if (isTableField(type)) {
        return <TableSelect tableName={tableName} type={type} {...props} />;
    }

    if (Array.isArray(type)) {
        return CommonSelect;
    }

    switch (type) {
        case "options":
            return CommonSelect;
        case "image":
            return UploadComponent;
        case "number":
            return InputNumber;
        default:
            return Input;
    }
}

export default function CommonInput({ type, tableName, ...props }) {
    const InputElement = inputFactory(type, tableName, props);
    return isValidElement(InputElement) ? (
        InputElement
    ) : (
        <InputElement type={type} {...props} />
    );
}
