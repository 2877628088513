import CommonTable from "../../shared/components/CommonTable";
import apiFactory from "../../apis/apiFactory";
import columns from "./configs/table";

/**
 * 通用增删改查页面
 */
export default function WorkNew() {
    const apiInstance = apiFactory({
        name: "product",
        baseUrl: "m/",
        onCallApi(method, params) {
            if (method === "create") {
                params.data.ossKeyId = Number(params.data.ossFile.id);
                params.data.cnTitle =
                    params.data.cnTitle || params.data.engTitle || "默认填充";
                params.data.cnDescription =
                    params.data.cnDescription ||
                    params.data.cnDescription ||
                    "默认填充";
                if (params.data?.ossVideoFile?.id) {
                    params.data.ossVideoKeyId = Number(
                        params.data.ossVideoFile.id,
                    );
                }
            }
        },
    });
    return <CommonTable columns={columns} api={apiInstance} />;
}
