import { TYPES } from "./documentTypes";
const columns = [
    {
        label: "ID",
        key: "id",
        isPrimary: true,
        canEdit: false,
    },
    {
        label: "唯一Key",
        key: "docKey",
    },
    {
        label: "来源",
        key: "source",
    },
    {
        label: "类型",
        key: "type",
        type: "options",
        options: TYPES,
    },
    {
        label: "内容",
        key: "content",
        showInList: false,
    },
    {
        label: "菜单栏展示",
        key: "showOnMenu",
        type: "switch",
    },
    {
        label: "描述",
        key: "description"
    }
];
export default columns;
