import {
    useImperativeHandle,
    forwardRef,
    useCallback,
    useMemo,
    Fragment,
} from "react";
import { Form, Input } from "antd";
import CommonInput from "../../common/Input";

function formatValue(type, val) {
    switch (type) {
        case "number":
            return Number(val) || 0;
        default:
            return val;
    }
}

export default forwardRef(
    ({ structure = [], data = {}, name, ...otherProps }, ref) => {
        const [form] = Form.useForm();
        useImperativeHandle(ref, () => ({ form }));

        const aliasMap = useMemo(() => {
            return {};
        }, []);
        const syncAliasColumn = useCallback(
            (changed) => {
                for (var i in changed) {
                    if (aliasMap[i]) {
                        form.setFieldsValue({ [aliasMap[i]]: changed[i] });
                    }
                }
            },
            [form, aliasMap],
        );

        return (
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                style={{ padding: "20px 0" }}
                autoComplete="off"
                onValuesChange={syncAliasColumn}
            >
                {structure.map(
                    ({
                        label = "",
                        key = "",
                        canEdit = true,
                        type = "string",
                        alias = "",
                        options = [],
                    }) => {
                        alias && (aliasMap[key] = alias);
                        let initValue = formatValue(
                            type,
                            data?.[key] || data?.[alias],
                        );
                        return (
                            <Fragment key={key}>
                                <Form.Item
                                    hidden={!canEdit}
                                    key={key}
                                    label={label}
                                    name={key}
                                    initialValue={initValue}
                                >
                                    <CommonInput
                                        name={key}
                                        tableName={name}
                                        options={options}
                                        type={type}
                                        placeholder=""
                                        data={data}
                                    />
                                </Form.Item>
                                {alias && (
                                    // TODO 这里暂时只支持String
                                    <Form.Item
                                        hidden={true}
                                        key={alias}
                                        name={alias}
                                        initialValue={initValue}
                                    >
                                        <Input type="string" placeholder="" />
                                    </Form.Item>
                                )}
                            </Fragment>
                        );
                    },
                )}
            </Form>
        );
    },
);
