import React, { useState } from "react";
import { Routes, Route, HashRouter as Router } from "react-router-dom";

import Panel from "./shared/components/Panel";
import Login from "./shared/components/Login";
import useAuth from "./shared/hooks/useAuth";
import useMount from "./shared/hooks/useMount";
import flat from "./shared/utils/flat";
import { initAsyncRoutes } from "./pages/routes";

import "antd/dist/antd.min.css";
import "./App.css";

const Routers = () => {
    const [routes, setRoutes] = useState([]);
    const [flatRoutes, setFlatRoutes] = useState([]);
    const { isLogin } = useAuth();

    useMount(() => {
        if (isLogin) {
            initAsyncRoutes().then((routes) => {
                setRoutes(routes);
                setFlatRoutes(flat(routes, "children"));
            });
        }
    });

    if (!isLogin) {
        return <Login />;
    }

    return (
        <Panel routes={routes}>
            <Routes>
                {flatRoutes.map((route) => {
                    return (
                        <Route
                            key={route.path || route.key}
                            path={route.path}
                            element={<route.component />}
                        />
                    );
                })}
            </Routes>
        </Panel>
    );
};

export default function App() {
    return (
        <Router>
            <Routers />
        </Router>
    );
}
