import { useCallback, useEffect, useState } from "react";
import { login as loginApi } from "../../apis/user";
import storage from "../utils/storage";

export default function useAuth() {
    const [isLogin, setIsLogin] = useState(storage("access-token"));
    const [accessToken, setAccessToken] = useState(storage("access-token"));
    const login = useCallback(async (data) => {
        return await loginApi(data);
    }, []);

    const logout = useCallback(() => {
        storage("access-token", "");
        storage("refresh-token", "");
    }, []);

    const onLoginChange = useCallback(() => {
        setIsLogin(storage("access-token"));
        setAccessToken(storage("access-token"));
    }, []);

    useEffect(() => {
        window.addEventListener("setItemEvent", onLoginChange);
        return () => {
            window.removeEventListener("setItemEvent", onLoginChange);
        };
    }, [onLoginChange]);

    return {
        isLogin,
        logout,
        login,
        accessToken,
    };
}
