import { registerTable } from "../../../shared/libs/table-engine";
import { userApi, roleApi } from "./api";
import { menusTable } from "../../perm/config/tables";
/**
 * 用户表
 */

//  account: "admin"
//  avatar: "http://localhost:8081/static/67f57d2058984103afc54d164aff5648.jpeg"
//  createDate: "2021-11-15T16:09:23.000Z"
//  email: "123@qq.com"
//  id: "1"
//  phoneNum: "18374914568"
//  status: 1
//  type: 1
//  updateDate: "2021-11-15T16:09:23.000Z"

// "id": "string",
//       "name": "string",
//       "remark": "string"

export const roleTable = registerTable("role", {
    api: roleApi,
    structure: [
        {
            label: "ID",
            key: "id",
            isPrimary: true,
            canEdit: false,
        },
        {
            label: "角色名",
            key: "name",
            isLabel: true,
        },
        {
            label: "备注",
            key: "remark",
        },
        {
            label: "菜单列表",
            key: "menuIds",
            type: [`table:${menusTable.name}.id`],
        },
    ],
});

export const userTable = registerTable("user", {
    api: userApi,
    structure: [
        {
            label: "ID",
            key: "id",
            isPrimary: true,
            canEdit: false,
        },
        {
            label: "用户名",
            key: "account",
        },
        {
            label: "头像",
            key: "avatar",
            type: "image",
            showInList: false,
        },
        {
            label: "邮箱",
            key: "email",
        },
        {
            label: "手机号",
            key: "phoneNum",
        },
        {
            label: "密码",
            key: "password",
            alias: "confirmPassword",
            type: "password",
            showInList: false,
        },

        {
            label: "状态",
            key: "status",
            type: [
                { value: 1, label: "使用中" },
                { value: 0, label: "已禁用" },
            ],
            showInList: false,
        },
        {
            label: "类型",
            key: "type",
            type: [
                { value: 1, label: "普通用户" },
                { value: 0, label: "超级管理员" },
            ],
            showInList: false,
        },
        {
            label: "创建时间",
            key: "createDate",
            canEdit: false,
            showInList: false,
        },
        {
            label: "更新时间",
            key: "updateData",
            canEdit: false,
            showInList: false,
        },
        {
            label: "关联角色",
            key: "roleIds",
            type: ["table:role.id"],
            showInList: false,
        },
    ],
    pageStart: 0,
    canAdd: false,
});
