import { createApi } from "../../../shared/libs/table-engine";
/**
 * 菜单相关接口
 */
export const userApi = createApi({
    baseUrl: "/user",
    responseParser: {
        query({ data, ...res }) {
            return { ...res, data: { roleIds: data } };
        },
    },

    requestParams: {
        query(id) {
            return {
                url: `/user/${id}/role`,
            };
        },
        update: ({ avatar, ...data }) => {
            let avatarString = avatar
                ? typeof avatar === "object"
                    ? avatar.url
                    : avatar
                : "";
            data.roleIds = (data?.roleIds || []).map((id) => {
                return id + "";
            });
            return {
                type: "PUT",
                url: "/user",
                data: { ...data, avatar: avatarString },
            };
        },
        create: ({ avatar, ...data }) => {
            let avatarString = avatar
                ? typeof avatar === "object"
                    ? avatar.url
                    : avatar
                : "";

            return {
                url: "register",
                type: "POST",
                data: { ...data, avatar: avatarString },
            };
        },
    },
});

export const roleApi = createApi({
    baseUrl: "/role",
    responseParser: {
        list({ data, ...res }) {
            return { ...res, data: { list: data } };
        },
        query({ data = [], ...res }) {
            return { ...res, data: { menuIds: data } };
        },
    },
    requestParams: {
        // delete({ id }) {
        //     return {
        //         url: "role/" + id,
        //         type: "DELETE",
        //     };
        // },
        query: (id) => {
            // console.log(id);
            return {
                url: `role/one/${id}/perms`,
                type: "GET",
            };
        },
        create(data) {
            data.menuIds = (data?.menuIds || []).map((id) => {
                return id + "";
            });
            return { url: "/role", type: "POST", data };
        },
        update(data) {
            data.menuIds = (data?.menuIds || []).map((id) => {
                return id + "";
            });
            return { url: "/role", type: "PUT", data };
        },
    },
});
