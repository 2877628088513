import axios from "axios";
import { message } from "antd";
import storage from "./storage";
import { refreshToken } from "../../apis/user";
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    transformResponse: [
        function (dataStr) {
            try {
                const { code = 0, data = {}, msg, error } = JSON.parse(dataStr);
                // 登录成功了
                if (code === 200 && data?.accessToken) {
                    storage("access-token", data.accessToken);
                    storage("refresh-token", data.refreshToken);
                }
                return { success: code === 200, code, data, msg, error };
            } catch (error) {
                console.log(error);
                return {
                    success: false,
                    code: -100,
                    data: null,
                    msg: "请求失败",
                };
            }
        },
    ],
});
export async function request(
    { type = "get", data = {}, showTip = true, url, headers = {} },
    isRetry = false,
) {
    let result = {};
    try {
        let { data: responseData, status } = await axiosInstance.request({
            method: type,
            url: url,
            params: type.toLowerCase() === "get" ? data : {},
            data: type.toLowerCase() !== "get" ? data : {},
            headers: {
                Authorization: storage("access-token") || "",
                ...headers,
            },
        });
        if (responseData && status) {
            result = responseData;
        } else {
            result = { code: -100, msg: "网络请求失败！" };
        }
        console.log(result);
        if (showTip && result.msg && result.code !== 200) {
            if (result.error) {
                result.error.forEach((errorItem) => {
                    message.error(errorItem);
                });
            } else {
                message.error(result.msg || "请求失败");
            }
        }
        console.log("fetch-api: " + url, result);
        return result;
    } catch (error) {
        let { status, data = {} } = error?.response || {};
        if (status === 401) {
            const isRefreshToken = /update\/token/.test(url);
            if (isRefreshToken) {
                storage("access-token", "");
                storage("refresh-token", "");
            }
            if (isRetry) {
                return data;
            } else {
                const {
                    data: { accessToken = "" },
                } = await refreshToken();
                if (accessToken) {
                    return await request(arguments[0], true);
                } else {
                    message.error("登录已过期，请重新登录");
                    return data;
                }
            }
        }
        if (showTip && data.msg && data.code !== 200) {
            if (typeof data.error === "object") {
                data.error.forEach((errorItem) => {
                    message.error(errorItem);
                });
            } else {
                message.error(data.msg);
            }
        }
        return data;
    }
}
export default axiosInstance;
