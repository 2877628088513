import { createApi } from "../../../shared/libs/table-engine";

/**
 * 权限列表接口
 *  */
export const permissionsApi = createApi({
    name: "permissions",
    baseUrl: "/perm",
    requestParams: {
        list: {
            url: "/perm/all",
            method: "GET",
        },
    },
});

/**
 * 菜单相关接口
 */
export const menusApi = createApi({
    name: "menus",
    baseUrl: "/menu",
    responseParser: {
        list: (res) => {
            const { success, code, data, msg, error } = res;
            return { success, code, data: { list: data }, msg, error };
        },
    },
    apiFunction: {
        query: (params) => {
            return { success: true, code: 200, data: {} };
        },
    },
    requestParams: {
        list: {
            url: "/menu/all",
            type: "GET",
        },
        update: {
            url: "/menu",
            type: "PUT",
        },
        create: ({ parentId, ...data }) => {
            return {
                url: "menu",
                type: "POST",
                data: {
                    parentId: parentId ? parentId.toString() : "0",
                    ...data,
                },
            };
        },
    },
});

/**
 * 菜单关联权限接口
 */
export const menusPermApi = createApi({
    name: "menus-perm",
    baseUrl: "menu/one/",

    requestParams: {
        list: ({ id: menuId }) => {
            return {
                url: `menu/one/${menuId}/menu-perm`,
                type: "GET",
            };
        },
        query: ({ id }) => {
            return {
                url: `menu/one/${id}/menu-perm`,
                type: "GET",
            };
        },
        search: ({ id }) => {
            return {
                url: `menu/one/${id}/menu-perm`,
                type: "GET",
            };
        },
    },
});
