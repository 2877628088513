import { Select } from "antd";

export default function CommonSelect({ options, type, ...props }) {
    const optionsList = Array.isArray(type) ? type : options;
    return (
        <Select {...props}>
            {optionsList.map(({ label, value }, index) => {
                return (
                    <Select.Option key={index} value={value}>
                        {label}
                    </Select.Option>
                );
            })}
        </Select>
    );
}
