import {
    FieldStringOutlined,
    AliwangwangOutlined,
    SafetyOutlined,
    HomeOutlined,
    MenuFoldOutlined,
    UserOutlined,
    UsergroupDeleteOutlined,
} from "@ant-design/icons";
import Edit from "./document/views/Edit";
import DocumentNew from "./document-new";
import Contact from "./contact/views/Contact";
import WorkNew from "./work-new";
import Perm from "./perm";
import User from "./user";
import Role from "./user/role";
import { getAllMenuDocuments } from "../apis/document";
import { getAllMenu } from "../apis/user";

const routesMap = {
    dashboard: {
        path: "/",
        component: WorkNew,
        icon: <HomeOutlined />,
    },
    document: {
        path: "/document",
        component: DocumentNew,
        icon: <FieldStringOutlined />,
    },
    perm_users: {
        code: "perm_users",
        path: "/user",
        component: User,
        icon: <UserOutlined />,
    },
    perm_roles: {
        path: `/user/role`,
        component: Role,
        icon: <UsergroupDeleteOutlined />,
    },
    contact_us: {
        path: "/contact",
        component: Contact,
        icon: <AliwangwangOutlined />,
    },
    perm: {
        path: "/perm",
        component: Perm,
        icon: <SafetyOutlined />,
    },
};

function _getRouteItemByCode(code) {
    return routesMap[code];
}
// 获取当前用户有的菜单
export async function getRoutes() {
    const { data = [] } = await getAllMenu();
    return data
        .reduce((routes, { name, orderNum: sort = 0, code = "" }) => {
            const hasRoute = routes.find(({ key }) => key === code);
            const route = _getRouteItemByCode(code);
            !hasRoute &&
                route &&
                routes.push({ page: name, key: code, sort, ...route });
            return routes;
        }, [])
        .sort((a, b) => b.sort - a.sort);
}

export const initAsyncRoutes = async () => {
    const routes = await getRoutes();
    console.log("all routes", routes);
    const docRes = await getAllMenuDocuments();
    return [
        ...routes,
        {
            key: "config-center",
            page: "配置中心",
            component: Contact,
            icon: <MenuFoldOutlined />,
            children: (docRes?.data?.list || [])
                .filter((d) => Boolean(d.description))
                .map((doc) => {
                    return {
                        path: `/edit-doc/${doc.id}`,
                        page: doc.description,
                        component: Edit,
                        icon: <MenuFoldOutlined />,
                    };
                }),
        },
    ];
};
