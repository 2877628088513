import { Form, Input, Checkbox, Button, Card } from "antd";
import useAuth from "../../hooks/useAuth";
import "./style.scss";
export default function Login() {
    const { login } = useAuth();
    return (
        <div className="login-wrap">
            <Card className="login-wrap-card" style={{ width: 500 }}>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={login}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Account"
                        name="account"
                        rules={[
                            {
                                required: true,
                                message: "Please input your account!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                        wrapperCol={{ offset: 8, span: 16 }}
                    >
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}
