export default function createDocumentParams({
    source,
    id,
    docKey,
    description,
    content,
    type,
}) {
    return {
        source,
        id,
        docKey,
        description,
        content,
        type: typeof type === "string" ? type : type?.value,
    };
}
