import { useCallback, useMemo } from "react";
import { Row, Col, Button, Table, Tag, Modal, message } from "antd";
import useTable from "../../hooks/useTable";
import apiFactory from "../../../apis/apiFactory";
import ActionButtons from "./components/ActionButtons";
import useMount from "../../hooks/useMount";
import * as modal from "./components/FormModal";
import OssImage from "./components/OssImage";

/**
 * 通用增删改查组件
 */
export default function CommonTable({
    name = "",
    columns = [],
    api = null,
    // 编辑的表单
    // TODO: 用于扩展
    // editModal = null,
    // TODO: 用于扩展
    // actions = () => {},
}) {
    const apiInstance = useMemo(() => {
        return api || apiFactory({ name });
    }, [api, name]);

    const fetchList = async (params = {}, pageNo = 1, pageSize) => {
        const {
            data: { list = [], total = 0 },
            success = false,
            msg,
        } = await apiInstance.list({ page: pageNo - 1, size: pageSize });
        if (success) {
            return {
                success: true,
                data: list,
                total,
            };
        }
        return {
            success: false,
            data: [],
            total: 0,
            message: msg,
        };
    };

    const { search, loading, reload, tableProps } = useTable({
        pageSize: 10,
        request: fetchList,
    });

    /**
     * 增
     */
    const createItem = useCallback(
        async (value) => {
            const res = await apiInstance.create(value);
            if (res.success) {
                message.success("创建成功");
                reload();
            } else {
                message.error("创建失败！" + res.msg);
            }
            return res;
        },
        [reload, apiInstance],
    );
    /**
     * 删
     */
    const removeHandle = useCallback(
        (id) => () => {
            Modal.confirm({
                content: "是否确认删除",
                cancelText: "取消",
                okText: "确定",
                onOk: async () => {
                    const { success } = await apiInstance.remove(id);
                    if (success) {
                        message.success("删除成功");
                    }
                    reload();
                },
            });
        },
        [reload, apiInstance],
    );

    /**
     * 改
     */
    const updateItemById = async (value) => {
        const res = await apiInstance.update(value);
        if (res.success) {
            message.success("更新成功");
            reload();
        } else {
            message.error("更新失败！" + res.msg);
        }
        return res;
    };

    /**
     * 弹出改动弹窗
     */
    const editHandle =
        ({ id, initData }) =>
        async () => {
            const {
                success = false,
                data = {},
                msg = "",
            } = await apiInstance.query(id);
            if (success) {
                modal.edit({
                    columns,
                    data: { id, ...initData, ...data },
                    submit: (formData) => {
                        return updateItemById(formData);
                    },
                });
            } else {
                message.error("请求失败!" + msg);
            }
        };

    /**
     * 创建 Table 列头数据
     */
    const createColumns = () => {
        const tableColumns = columns.reduce(
            (
                total,
                { label, key, alias, showInList = true, type, options },
            ) => {
                showInList &&
                    total.push({
                        title: label,
                        dataIndex: alias || key,
                        key,
                        render: createColumnsRender({ type, options }),
                    });
                return total;
            },
            [],
        );
        const primaryKey = columns.find((isPrimary) => {
            return isPrimary;
        });
        const { key = "" } = primaryKey || {};
        tableColumns.push({
            title: "操作",
            dataIndex: key,
            key: "action",
            render: (id, initData) => {
                return (
                    <ActionButtons
                        onDelete={removeHandle(id)}
                        onEdit={editHandle(initData)}
                    />
                );
            },
        });
        return tableColumns;
    };
    function createColumnsRender({ type = "string", options = [] }) {
        if (columnRender[type]) {
            return columnRender[type];
        }
        switch (type) {
            case "options":
                return (optionValue) => {
                    const curOption = options.find(({ value, label }) => {
                        return optionValue === value;
                    });
                    return curOption?.label || optionValue;
                };
            case "switch":
                return (value) => {
                    return (
                        <Tag color={value ? "blue" : "green"}>
                            {value ? "是" : "否"}
                        </Tag>
                    );
                };
            default:
                break;
        }
    }
    const columnRender = {
        image: (src) => {
            return <OssImage width={100} src={src} />;
        },
    };

    useMount(() => {
        search();
    }, []);

    return (
        <div>
            <Row className="mt-10">
                <Col span={24}>
                    <Button
                        type="primary"
                        onClick={() => {
                            modal.create({
                                columns,
                                submit: (v) => createItem(v),
                            });
                        }}
                    >
                        新增
                    </Button>
                </Col>
            </Row>
            {/* 查询 */}
            <Row></Row>
            <Table
                className="mt-10"
                rowKey="id"
                columns={createColumns()}
                loading={loading}
                {...tableProps}
            />
        </div>
    );
}
