import CommonTable from "../../shared/components/CommonTable";
import apiInstance from "./configs/apis";
import columns from "./configs/table";

/**
 * 通用增删改查页面
 */
export default function WorkNew() {
    return <CommonTable columns={columns} api={apiInstance} />;
}
