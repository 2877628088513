import {
    LoadingOutlined,
    PlusOutlined,
    CloseCircleOutlined,
} from "@ant-design/icons";
import { Upload, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { refreshToken } from "../../apis/user";
import useAuth from "../hooks/useAuth";
import { getBase64 } from "../utils/images";
import videoJpg from "./assets/video.jpg";

export default function VideoUploadComponent({
    onChange,
    fileType = ["video/mp4"],
    limit = 5,
    value = {},
    business = {},
    thumbnail = { width: 1000, quality: 70 },
    isString = false,
}) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [imageSize] = useState({});
    const { accessToken } = useAuth();

    useEffect(() => {
        setImageUrl(value?.url ? videoJpg : "");
    }, [value]);

    async function beforeUpload(file) {
        if (loading) {
            message.warn("正在上传中，请勿重复操作");
            throw new Error("正在上传中，请勿重复操作");
        }
        const isInType = fileType.includes(file.type);
        if (!isInType) {
            message.error("请上传" + fileType.toString() + "格式的文件");
        }
        const isOverLimit = file.size / 1024 / 1024 > limit;
        if (isOverLimit) {
            message.error("文件大小超过" + limit + "M");
        }
        if (!isInType || isOverLimit) {
            throw new Error("文件不符合要求");
        }
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const handleChange = useCallback(
        (info) => {
            const _upload = async (info) => {
                const { file } = info;
                const { status, response = {} } = file;
                if (status === "uploading") {
                    setLoading(true);
                    onChange("");
                    return;
                }
                if (status === "done") {
                    getBase64(file.originFileObj, (imageUrl) => {
                        setImageUrl(videoJpg);
                        setLoading(false);
                    });
                }
                if (status === "error") {
                    setLoading(false);
                    message.error(response.error || "上传失败，请重试");
                }
                if (response.code) {
                    if (response.code === 401) {
                        const res = await refreshToken();
                        if (res.code === 200) {
                            message.warn("");
                        } else {
                            message.error(res?.msg || "上传失败，请重试");
                        }
                    }
                    if (response.code !== 200) {
                        if (response.error) {
                            message.error(response.error || "上传失败");
                        }
                        onChange(null);
                    } else {
                        const _value = isString
                            ? response?.data?.[0]?.url
                            : response?.data[0];
                        onChange(_value);
                    }

                    setLoading(false);
                }
            };
            return _upload(info);
        },
        [onChange, isString],
    );
    return (
        <div>
            <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={process.env.REACT_APP_API_URL + "/oss/upload"}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                data={{
                    needThumbnail: !!thumbnail,
                    ...thumbnail,
                    business: JSON.stringify({ ...business, ...imageSize }),
                }}
                headers={{
                    Authorization: accessToken,
                }}
            >
                {imageUrl ? (
                    <div
                        className="img_wrap"
                        style={{
                            position: "relative",
                        }}
                    >
                        <CloseCircleOutlined
                            style={{
                                position: "absolute",
                                right: -20,
                                top: -20,
                                padding: 5,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onChange("");
                            }}
                        />
                        <img
                            src={imageUrl}
                            alt=""
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                            }}
                        />
                    </div>
                ) : (
                    uploadButton
                )}
            </Upload>
        </div>
    );
}
