import React, { useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import useAuth from "../hooks/useAuth";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const APP_NAME =
    window.location.hostname === "localhost" ? "擎天柱" : "TIP TOPCG";

function SiderMenus({ onSelect = () => {}, menus = [], ...otherProps }) {
    return (
        <Menu theme="dark" mode="inline" onSelect={onSelect} {...otherProps}>
            {menus.map(({ path, icon, page, key = "", children = [] }) => {
                if (children.length) {
                    return (
                        <SubMenu key={path || key} icon={icon} title={page}>
                            {children.map((subMenu) => {
                                return (
                                    <Menu.Item
                                        key={subMenu.path || subMenu.key}
                                    >
                                        {subMenu.page}
                                    </Menu.Item>
                                );
                            })}
                        </SubMenu>
                    );
                }
                return (
                    <Menu.Item key={path} icon={icon}>
                        {page}
                    </Menu.Item>
                );
            })}
        </Menu>
    );
}

export default function Panel({ children, routes = [] }) {
    const [collapsed, setCollapsed] = useState(false);
    const { logout } = useAuth();
    const _location = useLocation();
    const navigate = useNavigate();

    const toggle = useCallback(() => {
        setCollapsed(!collapsed);
    }, [collapsed, setCollapsed]);

    return (
        <Layout className="app-layout">
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                className=""
            >
                <div className="logo" style={{ overflow: "hidden" }}>
                    {APP_NAME}
                </div>
                <SiderMenus
                    onSelect={({ key }) => {
                        navigate(key);
                    }}
                    defaultSelectedKeys={[_location.pathname]}
                    menus={routes}
                />
            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{ padding: 0 }}
                >
                    {React.createElement(
                        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                        {
                            className: "trigger",
                            onClick: toggle,
                        },
                    )}
                    <Button
                        type="link"
                        size="small"
                        style={{
                            position: "absolute",
                            right: 15,
                            top: 15,
                        }}
                        onClick={logout}
                    >
                        退出
                    </Button>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: "24px 16px",
                        padding: 24,
                        minHeight: 280,
                        overflow: "auto",
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}
