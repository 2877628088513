/**
 * 用于列表组件
 */
import { useCallback } from "react";
import { Table, Modal, message, Row, Col, Button } from "antd";
import useTable from "./hooks/useTable";
import ActionButtons from "./components/ActionButtons";
import useMount from "../../../../hooks/useMount";
import * as modal from "../common/FormModal";
import OssImage from "../common/Image";

/**
 * 通用增删改查组件
 */
export default function CommonTable({
    table = null,
    topButtons = (val) => {
        return val;
    },
    // 编辑的表单
    // TODO: 用于扩展
    // editModal = null,
    // TODO: 用于扩展
    // actions = () => {},
}) {
    const { api, pageStart = 0 } = table;

    // 获取列表
    const fetchList = async (params = {}, pageNo = 1, pageSize) => {
        const res = await api.list({
            ...params,
            page: pageNo + (pageStart - 1),
            size: pageSize,
        });
        const {
            data: { list = [], total = 0 },
            success = false,
            msg,
        } = res;
        return {
            success,
            data: list,
            total,
            message: msg,
        };
    };

    const { search, loading, reload, tableProps } = useTable({
        pageSize: 10,
        request: fetchList,
    });

    /**
     * 增
     */
    const createItem = useCallback(
        async (value) => {
            const res = await api.create(value);
            if (res.success) {
                message.success("创建成功");
                reload();
            } else {
                message.error("创建失败！" + res.msg);
            }
            return res;
        },
        [reload, api],
    );

    /**
     * 删
     */
    const removeHandle = useCallback(
        (id) => () => {
            Modal.confirm({
                content: "是否确认删除",
                cancelText: "取消",
                okText: "确定",
                onOk: async () => {
                    const { success } = await api.remove(id);
                    if (success) {
                        message.success("删除成功");
                    }
                    reload();
                },
            });
        },
        [reload, api],
    );

    /**
     * 改
     */
    const updateItemById = async (value) => {
        const res = await api.update(value);
        if (res.success) {
            message.success("更新成功");
            reload();
        } else {
            throw new Error("更新失败！" + res.msg);
            // message.error("更新失败！" + res.msg);
        }
        return res;
    };

    /**
     * 弹出改动弹窗
     */
    const { structure = [], name } = table;
    const editHandle = (initData) => async () => {
        const {
            // success = false,
            data = {},
            // msg = "",
        } = (await api.query(initData?.id)) || {};
        modal.edit({
            structure,
            data: { ...initData, ...data },
            name,
            submit: (formData) => {
                return updateItemById(formData);
            },
        });
    };

    /**
     * 创建 Table 列头数据
     */
    const createColumns = () => {
        const { structure = [] } = table;
        const tableColumns = structure.reduce(
            (
                total,
                { label, key, alias, showInList = true, type, options },
            ) => {
                showInList &&
                    total.push({
                        title: label,
                        dataIndex: alias || key,
                        key,
                        render: createColumnsRender({ type, options }),
                    });
                return total;
            },
            [],
        );
        const { key = "id" } =
            structure.find((isPrimary) => {
                return isPrimary;
            }) || {};
        tableColumns.push({
            title: "操作",
            dataIndex: key,
            key: "action",
            render: (id, initData) => {
                return (
                    <ActionButtons
                        onDelete={removeHandle(initData)}
                        onEdit={editHandle(initData)}
                    />
                );
            },
        });
        return tableColumns;
    };
    function createColumnsRender({ type = "string", options = [] }) {
        if (columnRender[type]) {
            return columnRender[type];
        }
        switch (type) {
            case "options":
                return (optionValue) => {
                    const curOption = options.find(({ value, label }) => {
                        return optionValue === value;
                    });
                    return curOption?.label || optionValue;
                };

            default:
                break;
        }
    }
    const columnRender = {
        image: (src) => {
            return <OssImage width={100} src={src} />;
        },
    };

    useMount(() => {
        search();
    }, []);

    const createButton = [
        <Button
            key="create"
            type="primary"
            onClick={() => {
                modal.create({
                    structure,
                    data: {},
                    name,
                    submit: (formData) => {
                        return createItem(formData);
                    },
                });
            }}
        >
            新增
        </Button>,
    ];

    return (
        <div>
            <Row className="mt-10">
                <Col span={24}>{topButtons(createButton)}</Col>
            </Row>
            {/* 查询 */}
            {/* <Row></Row> */}
            <Table
                className="mt-10"
                rowKey="id"
                columns={createColumns()}
                loading={loading}
                {...tableProps}
            />
        </div>
    );
}
