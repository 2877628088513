import { useState } from "react";
import { Select } from "antd";

import useMount from "../../../../../../hooks/useMount";
import {
    parseTableField,
    getTable,
    getLabelKey,
    getFieldByName,
} from "../../../../modules/table";

const { Option } = Select;


// 联表查询
export default function JoinTableSelect({
    tableName: fromTableName,
    type,
    onChange = () => {},
    data: searchParams,
    name,
}) {
    // 是否多选
    const isMultiple = Array.isArray(type);
    // 当前字段直接关联的表实例
    let { tableName: joinTableName = "", fieldName: joinTableField = "" } =
        parseTableField(type, fromTableName);
    const joinTable = getTable(joinTableName);
    const joinTableApi = joinTable?.api;
    const labelKeys = getLabelKey(joinTable);
    const initValue = searchParams[name];

    // 获取默认值
    const {
        default: {
            value: defaultValue = undefined,
            label: defaultLabel = "",
        } = {},
    } = getFieldByName(joinTable, name) || {};
    const [selectedValue, setSelectedValue] = useState(
        initValue || defaultValue,
    );

    const [optionsList, setOptionsList] = useState([]);

    // 获取选项列表
    const fetchOptionsList = async () => {
        const { data: { list = [] } = {} } = await joinTableApi.list(
            searchParams,
        );
        setOptionsList(list);
    };

    useMount(() => {
        fetchOptionsList();
    });

    const getItemLabel = (item) => {
        return labelKeys.reduce((total, key) => {
            return `${total}${total ? " - " : ""}${item[key]}`;
        }, "");
    };

    // 当前选择器的默认值
    return (
        <Select
            mode={isMultiple ? "multiple" : ""}
            value={selectedValue}
            onChange={(val) => {
                setSelectedValue(val);
                onChange(val);
            }}
        >
            {defaultValue !== undefined && (
                <Option value={defaultValue}>{defaultLabel}</Option>
            )}
            {optionsList.map((item) => {
                const value = item[joinTableField];
                return (
                    <Option key={item[joinTableField]} value={value}>
                        {getItemLabel(item)}
                    </Option>
                );
            })}
        </Select>
    );
}
