import { Input, Select, InputNumber, Switch } from "antd";
import UploadComponent from "../../../Upload";
import VideoUploadComponent from "../../../VideoUpload";

export function CommonSelect({ options, ...props }) {
    return (
        <Select {...props}>
            {options.map(({ label, value }) => {
                return (
                    <Select.Option key={value} value={value}>
                        {label}
                    </Select.Option>
                );
            })}
        </Select>
    );
}

export function inputFactory(type, props) {
    switch (type) {
        case "options":
            return CommonSelect;
        case "image":
            return UploadComponent;
        case "video":
            return VideoUploadComponent;
        case "number":
            return InputNumber;
        case "switch":
            return Switch;
        default:
            return Input;
    }
}

export default function CommonInput({ type, value, ...props }) {
    const InputElement = inputFactory(type, value, props);
    if (type === "switch") {
        return <InputElement type={type} checked={value} {...props} />;
    }
    return <InputElement type={type} value={value} {...props} />;
}
